import React, { Component } from "react";
import {
  showModal,
  historyMessages,
  deleteMessages,
  logoutUser,
} from "../../redux/actions/actions";
import "../../style/table-fixed-header.css";
import moment from "moment-timezone";

//REDUX
import { connect } from "react-redux";
import { getMessages, ifloggedIn } from "../../config/config";

class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemsToDel: [],
      newMessages: [],
    };
  }

  isLoggedin = async () => {
    const { isLoggedIn, user } = this.props;
    const url = this?.props?.location?.pathname;
    await ifloggedIn({ isLoggedIn, user, url }).then((response) => {
      if (response) {
        this.logout();
      }
    });
  };

  logout = () => {
    const { dispatch } = this.props;
    dispatch(logoutUser());
    this.props.history.push("/");
  };

  componentDidMount() {
    document.title = "Messages";
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.isLoggedin();
    this.transcribeMessages();
    this.intervalID = setInterval(() => {
      this.transcribeMessages();
    }, 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  getMHis = () => {
    const { dispatch } = this.props;
    dispatch(historyMessages()).then((res) => {
      this.transcribeMessages();
    });
  };

  transcribeMessages = async () => {
    this.setState({ newMessages: [] });
    let response = await getMessages();
    let messages = response.data;
    let newMessages = [];
    let token = JSON.parse(localStorage.getItem("SPToken"));
    for (let i in messages) {
      if (messages[i].hide_messages) {
        let hideMsgs = JSON.parse(messages[i]?.hide_messages);
        let showMsg = true;
        for (let j in hideMsgs) {
          if (hideMsgs[j].clientID === token.id) {
            showMsg = false;
            break;
          }
        }
        if (showMsg) {
          newMessages.push(messages[i]);
        }
      } else {
        newMessages.push(messages[i]);
      }
    }
    this.setState({ newMessages: newMessages });
  };

  selectMessage = (item) => (event) => {
    let check = event.target.checked;
    let itemsToDel = this.state.itemsToDel;
    for (let i = 0; i < itemsToDel.length; i++) {
      if (itemsToDel[i].id === item.id) {
        itemsToDel.splice(i, 1);
      }
    }
    if (check) {
      itemsToDel.push(item);
    }
  };

  delMessage_clickHandler = async (event) => {
    event.preventDefault();
    this.setState({ newMessages: [] });
    const { itemsToDel } = this.state;
    const { dispatch } = this.props;
    let token = JSON.parse(localStorage.getItem("SPToken"));
    if (itemsToDel && itemsToDel.length) {
      await dispatch(
        deleteMessages({
          data: itemsToDel,
          clientID: token?.id,
        })
      ).then((res) => {
        dispatch(
          showModal("FULL_SCREEN_MODAL", {
            title: "DELTED",
            content: "Messages Deleted.",
          })
        );
        this.setState({ newMessages: [] });
        this.getMHis();
      });
    } else {
      dispatch(
        showModal("FULL_SCREEN_MODAL", {
          title: "ERROR",
          content: "Please select the messages you want to delete.",
        })
      );
    }
  };

  render() {
    const { newMessages } = this.state;

    return (
      <div>
        <section className="hero_in tours" style={{ height: "8em" }}>
          <div className="wrapper">
            <div className="container">
              <h1 className="fadeInUp">Messages</h1>
            </div>
          </div>
        </section>

        <div className="contact_info" style={{ height: "100vh" }}>
          <div className="container">
            <button
              className="btn-sm btn-danger"
              style={{ float: "left" }}
              onClick={this.delMessage_clickHandler}>
              Del. Msgs
            </button>
          </div>
          <hr style={{ color: "white" }} />
          <div
            className="container"
            style={{
              overflow: "scroll",
              height: "70%",
              borderStyle: "solid",
              borderColor: "white",
            }}>
            <table
              className="table w-100 table-sm table-wrapper text-white h-50"
              style={{ marginRight: "auto", marginLeft: "auto" }}>
              <thead>
                <tr>
                  <th style={{ backgroundColor: "#3A3B3C" }}>SUBJECT</th>
                  <th style={{ backgroundColor: "#3A3B3C" }}>CONTENT</th>
                  <th style={{ backgroundColor: "#3A3B3C" }}>DATE</th>
                </tr>
              </thead>
              <tbody>
                {newMessages?.length > 0 ? (
                  newMessages.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td className="w-25">{item.message_subject}</td>
                        <td className="w-50">{item.message_content}</td>
                        <td className="w-25">
                          {moment(item.message_date).format("DD-MM-YYYY hh:mm")}
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            value=""
                            onClick={this.selectMessage(item)}
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr key="1">
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  messages: state.raceReducer.messages,
  delMsg: state.raceReducer.delMsg,
  isLoggedIn: state.authReducer.isLoggedIn,
  user: state.authReducer.user,
});

export default connect(mapStateToProps)(Messages);
