import { combineReducers } from 'redux'

import modalReducer from './modalReducer'
import authReducer from './authReducer'
import homeTableReducer from './homeTableReducer'
import raceReducer from './raceReducer'


const reducers = combineReducers({
  modalReducer,
  authReducer,
  homeTableReducer,
  raceReducer,
});

export default reducers;