import {
  LOGOUT_USER, LOGIN_USER, UPDATE_AMOUNT, UPDATE_PLAN, UPDATE_PAUSED, UPDATE_ALLAMOUNT,
  UPDATE_SATPAUSED, UPDATE_POPPAUSED, UPDATE_TRANSACTIONS, UPDATE_COINSSUMMARY, GET_LEADBYID, GET_LEADBYID_ERROR, GET_LEADBYID_SUCCESS, GET_LEADBYID_STARTED
} from '../actions/actions';

const initialState = {
  isLoggedIn: false,
  user: null,
  coins: null,
  plan: null,
  paused: null,
  satPaused: null,
  rPopup: null,
  transactions: [],
  coinsSummary: [],
  data: [],
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGOUT_USER:
      return Object.assign({}, state, {
        isLoggedIn: false,
        user: null,
        coins: null,
        plan: null,
        paused: null,
        satPaused: null,
        rPopup: null,
        transactions: [],
      });
    case LOGIN_USER:
      return Object.assign({}, state, {
        isLoggedIn: true,
        user: action.user,
        coins: action.user.coins,
        plan: action.user.plan,
        paused: action.user.paused,
        satPaused: action.user.satPaused,
        rPopup: action.user.rPopup,
      });
    case UPDATE_AMOUNT:
      return Object.assign({}, state, {
        coins: state.coins + action.amount
      });
    case UPDATE_ALLAMOUNT:
      return Object.assign({}, state, {
        coins: action.amount
      });
    case UPDATE_PLAN:
      return Object.assign({}, state, {
        plan: action.plan
      });
    case UPDATE_PAUSED:
      return Object.assign({}, state, {
        paused: (action.paused === 0 ? 0 : 1)
      });
    case UPDATE_SATPAUSED:
      return Object.assign({}, state, {
        satPaused: (action.satPaused === 0 ? 0 : 1)
      });
    case UPDATE_POPPAUSED:
      return Object.assign({}, state, {
        rPopup: (action.rPopup === 0 ? 0 : 1)
      });
    case UPDATE_TRANSACTIONS:
      return Object.assign({}, state, {
        transactions: action.transactions
      });
    case UPDATE_COINSSUMMARY:
      return Object.assign({}, state, {
        coinsSummary: action.coinsSummary
      });
    // case GET_LEADBYID_STARTED:
    //   return (
    //     Object.assign,
    //     state,
    //     {
    //       ...state,
    //     }
    //   );
    // case GET_LEADBYID_SUCCESS:
    //   return Object.assign({}, state, {
    //     ...state,
    //     // loading: false,
    //     data: action.payload.data,
    //   });
    // case GET_LEADBYID_ERROR:
    //   return Object.assign({}, state, {
    //     ...state,
    //     // loading: false,
    //     data: [],
    //   });

    default:
      return state;
  }
}

export default authReducer;
