import { TABLE_DATA } from '../actions/actions';

const initialState = {
  tableData: [],
};

function homeTableReducer(state = initialState, action) {
  switch (action.type) {
    case TABLE_DATA:
      return Object.assign({}, state, {
        tableData: action.data
      });
    default:
      return state;
  }
}

export default homeTableReducer;
