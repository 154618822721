import { UPDATE_RACES, DEL_HISTORY, GET_MESSAGES, DELETE_MESSAGES, } from '../actions/actions';
import { CommentActions } from 'semantic-ui-react';


const initialState = {
    races: [],
    date: '',
    totLeft: 0,
    messages: [],
    delMsg: '',
};

function raceReducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_RACES:
            return Object.assign({}, state, {
                races: action.races,
                date: action.date,
                totLeft: action.totLeft,
            });
        case DEL_HISTORY:
            return Object.assign({}, state, {
                item: action.item,
            });
        case GET_MESSAGES:
            return Object.assign({}, state, {
                messages: action.response.data,
            });
        case DELETE_MESSAGES:
            return Object.assign({}, state, {
                delMsg: action.response.msg,
            })
        default:
            return state;
    }
}

export default raceReducer;
