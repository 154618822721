import { SHOW_MODAL, HIDE_MODAL, PATH_NAME } from '../actions/actions';

const initialState = {
  modalType: null,
  modalProps: {},
  url: '',
};

function modalReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_MODAL:
      return Object.assign({}, state, {
        modalType: action.modalType,
        modalProps: action.modalProps
      });
    case HIDE_MODAL:
      return Object.assign({}, state, {
        modalType: null,
        modalProps: {}
      });
      case PATH_NAME:
        return Object.assign({}, state, {
          pathName: action.url
        })
    default:
      return state;
  }
}

export default modalReducer;
