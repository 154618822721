import React, { Suspense, lazy } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { usePromiseTracker } from "react-promise-tracker";

/* COMPONENTS */
import { CircleArrow as ScrollUpButton } from "react-scroll-up-button";
import Navbar from "./navbar/navbar";
import Footer from "./footer/footer";
import ModalRoot from "./modalRoot/modalRoot";
import Loader from "./loader/loader";
import Loading from "./loading/Loading";
// import Plans from "../pages/aboutUs/plans";
import Messages from "../pages/messages/Messages";

/* PAGES */
const Stats = lazy(() => import("../pages/stats/Stats.js"));
const Home = lazy(() => import("../pages/home/home"));
//const SmartSystem = lazy(() => import("../pages/smartSystem/smartSystem"))
const Preferences = lazy(() => import("../pages/preferences/preferences"));
const ContactUs = lazy(() => import("../pages/contactUs/contactUs"));
const SignUp = lazy(() => import("../pages/signUp/signUp"));
const Profile = lazy(() => import("../pages/profile/profile"));
const AccountSummary = lazy(() =>
  import("../pages/accountSummary/accountSummary")
);
const FAQ = lazy(() => import("../pages/faq/faq"));
//const Pricing = lazy(() => import("../pages/pricing/pricing"))
const Disclaimer = lazy(() => import("../pages/disclaimer/disclaimer"));
const Terms = lazy(() => import("../pages/terms/terms"));
const Privacy = lazy(() => import("../pages/privacy/privacy"));
const AboutUs = lazy(() => import("../pages/aboutUs/aboutUs"));
const Abbreviations = lazy(() => import("../pages/aboutUs/abbreviations"));
const Recommendations = lazy(() => import("../pages/aboutUs/recommendations"));
const Summary = lazy(() => import("../pages/aboutUs/summary"));
// const Trial = lazy(() => import("../pages/aboutUs/trial"))
const Tips = lazy(() => import("../pages/aboutUs/tips"));
const QuesotinsAnswers = lazy(() =>
  import("../pages/aboutUs/questionsAnswers")
);
const Verify = lazy(() => import("../pages/verify/verify"));
const Transactions = lazy(() => import("../pages/transactions/transactions"));
const CoinsSummary = lazy(() => import("../pages/coinsSummary/coinsSummary"));
const Calculator = lazy(() => import("../pages/calculator/calculator-view"));
const Platinum = lazy(() =>
  import("../pages/calculator/calculator-view-platinum")
);
const bronze = lazy(() => import("../pages/calculator/calculator-view-bronze"));
const CalculatorNew = lazy(() =>
  import("../pages/calculator/calculator-view-new")
);
const ErrorPage = lazy(() => import("../pages/error"));
const LinkApp = lazy(() => import("../pages/appointment/appointment2"));
const LinkAppID = lazy(() => import("../pages/appointment/appointmentID"));

const PromiseLoader = () => {
  const { promiseInProgress } = usePromiseTracker();
  return promiseInProgress && <Loader isFullscreen isLoading />;
};

class App extends React.Component {
  render() {
    return (
      <div>
        <Suspense fallback={<Loading />}>
          <BrowserRouter>
            <ScrollUpButton
              ToggledStyle={{ zIndex: "999999 !important", right: "50%" }}
            />
            <PromiseLoader />
            <ModalRoot />
            <Navbar />
            <Switch>
              <Route exact path="/" component={Home} />

              {/*<Route exact path="/smartSystem" component={SmartSystem} />*/}
              <Route exact path="/stats" component={Stats} />
              <Route exact path="/preferences" component={Preferences} />
              <Route exact path="/contactUs" component={ContactUs} />
              <Route exact path="/appointment" component={LinkApp} />
              <Route exact path="/appointments/:id" component={LinkAppID} />
              <Route exact path="/todayMessages" component={Messages} />
              <Route exact path="/aboutUs" component={AboutUs} />
              <Route exact path="/abbreviations" component={Abbreviations} />
              <Route exact path="/qa" component={QuesotinsAnswers} />
              <Route
                exact
                path="/recommendations"
                component={Recommendations}
              />
              {/* <Route exact path="/plans" component= {Plans} /> */}
              <Route exact path="/summary" component={Summary} />
              {/* <Route exact path="/trial" component={Trial} /> */}
              <Route exact path="/signUp" component={SignUp} />
              <Route exact path="/profile" component={Profile} />
              <Route exact path="/account" component={AccountSummary} />
              <Route exact path="/transactions" component={Transactions} />
              <Route exact path="/coins" component={CoinsSummary} />
              <Route exact path="/faq" component={FAQ} />
              <Route exact path="/tips" component={Tips} />
              {/*<Route exact path="/pricing" component={Pricing} />*/}
              <Route exact path="/terms" component={Terms} />
              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/disclaimer" component={Disclaimer} />
              <Route exact path="/verify/:_id" component={Verify} />
              <Route exact path="/program" component={Calculator} />
              <Route exact path="/programNew" component={CalculatorNew} />
              <Route exact path="/platinum" component={Platinum} />
              <Route exact path="/bronze" component={bronze} />

              <Route component={ErrorPage} />
            </Switch>
            {this.props.pathName !== "programNew" && <Footer />}
          </BrowserRouter>
        </Suspense>
      </div>
    );
  }
}

// Redux
const mapStateToProps = (state) => ({
  pathName: state.modalReducer.pathName,
});

export default connect(mapStateToProps)(App);
