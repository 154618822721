/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import { trackPromise } from "react-promise-tracker";
import moment from "moment";
import { loadSession } from "../../utils/utils";
// USER TRACKING
//import {deviceDetect} from "react-device-detect";

// Images
import SpsLogo from "../../style/img/SpsLogo.png";
import SpsLogo1 from "../../style/img/SpsLogo1.png";

// API
import { logoutUserAuth } from "../../config/config";

// REDUX
import { connect } from "react-redux";
import { showModal, logoutUser, pathName } from "../../redux/actions/actions";
import { ifloggedIn } from "../../config/config";
import Coins from "../../style/img/coins.svg";
import Summary from "../../pages/aboutUs/abbreviations";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sticky: false,
      sidebarOpen: false,
      sub: false,
    };
  }

  // Component
  async Loggedin() {
    const { isLoggedIn, user } = this.props;
    const url = this?.props?.location?.pathname;
    await ifloggedIn({ isLoggedIn, user, url }).then((response) => {
      if (response) {
        this.logout();
      }
    });
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.Loggedin();
    // const client = JSON.parse(localStorage.getItem("SPToken"));
    // const SPSsession = JSON.parse(localStorage.getItem("SPSsession"));

    // loadSession(client, SPSsession, this.props);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  logout = () => {
    const { dispatch } = this.props;
    dispatch(logoutUser());
    this.props.history.push("/");
  };

  // Handlers

  handleScroll = () => {
    this.setState({
      sticky: window.scrollY > 1,
    });
  };
  refreshPage() {
    window.location.reload();
  }

  onMenuTap = () => {
    // this.refreshPage();
    this.setState({
      sidebarOpen: false,
    });
  };

  showSub = (event) => {
    event.preventDefault();
    const { sub } = this.state;
    if (sub) {
      this.setState({ sub: false });
    } else {
      this.setState({ sub: true });
    }
  };

  changePlan = async (e) => {
    e.preventDefault();
    const { dispatch } = this.props;

    dispatch(showModal("PLAN_MODAL"));
  };

  onRecharge = async (e) => {
    e.preventDefault();
    const { dispatch } = this.props;

    dispatch();
    //showModal('RECHARGE_MODAL')
  };

  onLogin = (e) => {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(showModal("AUTHENTICATION_MODAL", null));
  };

  onLogout = (evt) => {
    evt.preventDefault();
    const { dispatch } = this.props;
    trackPromise(
      logoutUserAuth({ email: this.props.user.email }).then((response) => {
        if (response.status === 500) {
          alert(response.message);
          this.props.history.push("/");
        }
        this.props.history.push("/");
        dispatch(logoutUser());
        localStorage.removeItem("SPToken");
      })
    );
  };

  onBurgerClicked = () => {
    const { sidebarOpen } = this.state;
    this.setState({ sidebarOpen: !sidebarOpen });
  };

  render() {
    const { sticky } = this.state;
    const { isLoggedIn, user, coins, plan } = this.props;
    // console.log(user)
    const token = JSON.parse(localStorage.getItem("SPToken"));

    return (
      <div>
        <header
          className={sticky ? "header menu_fixed sticky" : "header menu_fixed"}
          style={{ width: window.innerWidth < 1224 ? "25px" : "100%" , height : "50px"}}>
          {/* <div id="logo">
            <Link to="/">
              <img
                src={SpsLogo}
                width={350}
                height={45}
                data-retina="true"
                alt="Logo"
                className="logo_fixed-sticky"
              />
            
            </Link>
          </div> */}

          <span onClick={this.onBurgerClicked} className="btn_mobile">
            <div className="hamburger hamburger--spin" id="hamburger">
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
          </span>

          <nav id="menu" className="main-menu">
            <ul>
              <li>
                <span>
                  <Link to="/">Home</Link>
                </span>
              </li>
                <li>
                    <span>
                      <Link to="/stats">Stats</Link>
                    </span>
                </li>
              {isLoggedIn === true ? (
                <li>
                  {user.isPlat === 1 ? (
                    
                    <span>
                      <Link to="/platinum">Platinum</Link>
                    </span>
                  ) : user.isBronze === 1 ? (
                    <span>
                      <Link to="/bronze">Program</Link>
                    </span>
                  ) : (
                    <span>
                      <Link to="/program">Program</Link>
                    </span>
                  )}
                </li>
              ) : null}
              {/* {isLoggedIn === true ? (
                <li>
                  <span>
                    <Link to="/programNew">Program new</Link>
                  </span>
                </li>
              ):null

              }
              {isLoggedIn === true ? (
                <li>
                  <span>
                    <Link to="/account">Summary</Link>
                  </span>
                </li>) : null} */}
              <li>
                <span>
                  <Link to="#">About SPS</Link>
                </span>
                <ul>
                  <li>
                    <span>
                      <Link to="/aboutUs">About Us</Link>
                    </span>
                  </li>
                  <li>
                    <span>
                      <Link to="/recommendations">Recommendations</Link>
                    </span>
                  </li>
                  <li>
                    <span>
                      <Link to="/qa">Q&A</Link>
                    </span>
                  </li>
                  <li>
                    <span>
                      <Link to="/summary">Summary</Link>
                    </span>
                  </li>
                  <li>
                    <span>
                      <Link to="/abbreviations">Abbreviations</Link>
                    </span>
                  </li>
                  <li>
                    <span>
                      <Link to="/tips">Tips</Link>
                    </span>
                  </li>
                  {/* <li>
                    <span>
                      <Link to="/trial">Trial</Link>
                    </span>
                  </li> */}
                </ul>
              </li>
              <li>
                <span>
                  <Link to="/contactUs">Contact Us</Link>
                </span>
              </li>
              {/* <li>
                <span>
                  <Link to="/plans">Plans</Link>
                </span>
              </li> */}
              {isLoggedIn === true ? (
                <li>
                  <span>
                    <Link to="/todayMessages">Messages</Link>
                  </span>
                </li>
              ) : null}
              {/*<li>
                <span>
                  <Link to="#">Explore</Link>
                </span>
                <ul>
                  <li>
                    <span>
                    <Link to="/smartSystem">Smart System</Link>
                    </span>
                  </li>
                  <li>
                    <span>
                    <Link to="/history">History</Link>
                    </span>
                  </li>
                </ul>
              </li>*/}
              {/*<li>
                    <span>
                    <Link to="/history">History</Link>
                    </span>
                  </li>*/}
              {/*<li>
                <span>
                  <Link to="/pricing">Subscription</Link>
                </span>
              </li>*/}
              {isLoggedIn === false ? (
                <>
                  <li>
                    <span>
                      <Link to="#" onClick={this.onLogin}>
                        Sign In
                      </Link>
                    </span>
                    {/*<span>
                    <Link to="#">Account</Link>
                  </span>
                  <ul>
                    <li>
                      <a href="#" onClick={this.onLogin}>
                        Sign In
                      </a>
                    </li>*/}
                  </li>
                  <li>
                    <span>
                      <Link to="/signUp">Sign Up</Link>
                    </span>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <span>
                      <Link to="#">Welcome {user.name}</Link>
                    </span>
                    <ul>
                      {/* <li>
                      <span>
                        <Link to="/profile">Profile</Link>
                      </span>
                    </li>
                    <li>
                      <span>
                        <Link to="#" onClick={this.changePlan}>Change Plan</Link>
                      </span>
                    </li>
                    <li>
                      <span>
                        <Link to="#" onClick={this.onRecharge}>Recharge Coins</Link>
                      </span>
                    </li>
                    <li>
                      <span>
                        <Link to="/preferences">Preferences</Link>
                      </span>
                    </li>
                    <li>
                      <span>
                        <Link to="/transactions">Transactions</Link>
                      </span>
                    </li>
                    <li>
                      <span>
                        <Link to="/coins">Coins Summary</Link>
                      </span>
                    </li> */}
                      <li>
                        <span>
                          <Link to="/" onClick={this.onLogout}>
                            Logout
                          </Link>
                        </span>
                      </li>
                      <li>
                        <span>
                          <Link to="#">
                            EXP -{" "}
                            <span className="text-danger">
                              {this.state.expiry}
                            </span>
                          </Link>
                        </span>
                      </li>
                    </ul>
                  </li>
                  {/* <li>
                  <span onClick={this.onRecharge} style={{cursor: 'pointer', color: 'black' }}>
                    {plan === 'Silver' ?
                      <img src={Coins} alt="silver coins" style={{width: 30, height: 30, marginLeft: 10 }}/> :
                      <img src={Coins} alt="gold coins" style={{width: 30, height: 30, marginLeft: 10  }} />}  {coins}
                  </span>
                </li> */}
                </>
              )}
            </ul>
          </nav>

          <Menu
            width="60%"
            left
            isOpen={this.state.sidebarOpen}
            disableAutoFocus
            pageWrapId={"page-wrap"}
            id="mobile_menu">
            <ul>
              <li className="menu-item">
                <span>
                  <h4 className="text-white">
                    Welcome {user ? user.name : ""}
                  </h4>
                </span>
              </li>
              <li onClick={this.onMenuTap} className="menu-item">
                <span>
                  <Link to="/">Home</Link>
                </span>
              </li>
                <li>
                    <span>
                      <Link to="/stats">Stats</Link>
                    </span>
                </li>
                
              {isLoggedIn === true && user.isBronze === 0 ? (
                <li onClick={this.onMenuTap} className="menu-item">
                  <span> 
                    <Link to="/program">Program</Link>
                  </span>
                </li>
              ) : isLoggedIn === true && user.isBronze !== 0 &&(
                <li onClick={this.onMenuTap} className="menu-item">
                  <span>
                    <Link to="/bronze">Program</Link>
                  </span>
                </li>
              )}
              {/*<li onClick={this.onMenuTap}>
                <span>
                  <Link to="/smartSystem">Explore Smart System</Link>
                </span>
              </li>*/}
              {/*<li onClick={this.onMenuTap}>
                <span>
                  <Link to="/history">History</Link>
                </span>
              </li>*/}
              {/*<li onClick={this.onMenuTap}>
                <span>
                  <Link to="/pricing">Subscription</Link>
                </span>
              </li>*/}
              <li>
                <span>
                  <Link to="#" onClick={this.showSub}>
                    About SPS
                  </Link>
                </span>
                {this.state.sub ? (
                  <ul>
                    <li onClick={this.onMenuTap}>
                      <span>
                        <Link to="/aboutus">About Us</Link>
                      </span>
                    </li>
                    <li onClick={this.onMenuTap}>
                      <span>
                        <Link to="/recommendations">Recommendations</Link>
                      </span>
                    </li>
                    <li onClick={this.onMenuTap}>
                      <span>
                        <Link to="/qa">Q&A</Link>
                      </span>
                    </li>
                    <li onClick={this.onMenuTap}>
                      <span>
                        <Link to="/summary">Summary</Link>
                      </span>
                    </li>
                    <li onClick={this.onMenuTap}>
                      <span>
                        <Link to="/abbreviations">Abbreviations</Link>
                      </span>
                    </li>
                    <li onClick={this.onMenuTap}>
                      <span>
                        <Link to="/tips">Tips</Link>
                      </span>
                    </li>
                    {/* <li onClick={this.onMenuTap}>
                      <span>
                        <Link to="/trial">Trial</Link>
                      </span>
                    </li> */}
                  </ul>
                ) : null}
              </li>
              <li onClick={this.onMenuTap}>
                <span>
                  <Link to="/contactUs">Contact Us</Link>
                </span>
              </li>

              {isLoggedIn === true ? (
                <li onClick={this.onMenuTap}>
                  <span>
                    <Link to="/todayMessages">Msgs</Link>
                  </span>
                </li>
              ) : null}

              {/* <li onClick={this.onMenuTap}>
                <span>
                  <Link to="/plans">Plans</Link>
                </span>
              </li> */}

              {isLoggedIn === false ? (
                <ul>
                  <li onClick={this.onMenuTap}>
                    <Link to="#" onClick={this.onLogin}>
                      Sign In
                    </Link>
                  </li>
                  <li onClick={this.onMenuTap}>
                    <span>
                      <Link to="/signUp">Sign Up</Link>
                    </span>
                  </li>
                </ul>
              ) : (
                <ul>
                  <li onClick={this.onMenuTap}>
                    <Link to="/" onClick={this.onLogout}>
                      Logout
                    </Link>
                  </li>
                  <li onClick={this.onMenuTap}>
                    <Link to="#">
                      <span className="text-danger">
                        EXP:{" "}
                        {user.clientExpiryDate
                          ? moment(user.clientExpiryDate).format("DD-MM-YYYY")
                          : moment(token.clientExpiryDate).format("DD-MM-YYYY")}
                      </span>
                    </Link>
                  </li>
                </ul>
              )}
            </ul>
          </Menu>
        </header>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.authReducer.isLoggedIn,
  user: state.authReducer.user,
  coins: state.authReducer.coins,
  plan: state.authReducer.plan,
});

export default connect(mapStateToProps)(withRouter(Navbar));
