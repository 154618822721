import React from 'react';
import PropTypes from 'prop-types';

// Components
import { DotLoader } from 'react-spinners';

// CSS
import './loader.css';

const Loader = (props) => {
  const { isLoading, isFullscreen } = props;

  if (isFullscreen && isLoading) {
    return (
      <div className="screenloader-overlay">
        <div className="screenloader-container">
          <DotLoader sizeUnit="px" size={100} color="#28abe3" loading={isLoading} />
        </div>
      </div>
    );
  } if (isLoading) {
    return <DotLoader sizeUnit="px" size={100} color="#28abe3" loading={isLoading} />;
  }
  return null;
};

Loader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isFullscreen: PropTypes.bool.isRequired
};

export default Loader;
