import React, { Component } from "react";
import { connect } from "react-redux";
import { hideModal } from "../../redux/actions/actions";
import { forgotUserAuth } from "../../config/config";

class ForgotPassModal extends Component {
  state = {
    email: "",
    status: null,
  };

  onClose = () => {
    const { dispatch } = this.props;
    dispatch(hideModal());
  };

  onChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  onForgot = () => {
    const { email } = this.state;
    const { dispatch } = this.props;

    forgotUserAuth({ email: email })
        .then((response) => {
          // check if the email is not in the database or recovery email sent
          // https://itnext.io/password-reset-emails-in-your-react-app-made-easy-with-nodemailer-bb27968310d7
          if (response.message) {
            this.showStatus(response.message);
            return;
          }
          dispatch(hideModal());
        })
        .catch(() => {
          this.setState({ status: "Connection Problem. Please try again or contact customer support" });
        })
  }

  showStatus(status) {
    this.setState({ status: status.toUpperCase() });
  }

  render() {
    const { status } = this.state;
    return (
      <div className="authOverlay">
        <div id="sign-in-dialog" className="zoom-anim-dialog">
          <div className="small-dialog-header">
            <h3>Forgot Password</h3>
          </div>
          <div>
            <div className="sign-in-wrapper">
              <form
                onSubmit={(evt) => {
                  evt.preventDefault();
                }}
              >
                <div className="form-group">
                  <label>Email</label>
                  <input
                    autoComplete="username"
                    type="text"
                    className="form-control"
                    name="email"
                    id="email"
                    required
                    onChange={this.onChange}
                  />
                  <i className="icon_mail_alt"/>
                </div>

                {status !== null ? (
                  <div className="text-center">
                    <p style={{ color: "red" }}>{status}</p>
                  </div>
                ) : (
                  <div />
                )}
                <div className="text-center">
                  <input
                    type="submit"
                    value="Reset Password"
                    className="btn_1 full-width"
                    onClick={this.onForgot}
                  />
                </div>
                <div className="text-center">
                  <input
                    type="submit"
                    value="Close"
                    className="btn_1 full-width"
                    onClick={this.onClose}
                  />
                </div>
                <div className="text-center">
                  Don’t have an account? <a href="/signUp">Sign up</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(ForgotPassModal);
