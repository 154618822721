import React from 'react';
import { connect } from 'react-redux';

// Modals
import AuthenticationModal from '../authenticationModal/authenticationModal'
import FullscreenModal from '../fullScreenModal/fullScreenModal'
import RechargeModal from '../rechargeModal/rechargeModal'
import PlanModal from '../planModal/planModal'
import ForgotPassModal from'../forgotPassModal/forgotPassModal'
import selectionModal from '../selectionModal/selectionModal'


const MODAL_COMPONENTS = {
  AUTHENTICATION_MODAL: AuthenticationModal,
  FORGOTPASS_MODAL: ForgotPassModal,
  FULL_SCREEN_MODAL: FullscreenModal,
  RECHARGE_MODAL: RechargeModal,
  PLAN_MODAL: PlanModal,
  SELECTION_MODAL: selectionModal,
};

const ModalRoot = ({ modalType, modalProps }) => {
  if (!modalType) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[modalType];
  return <SpecificModal {...modalProps} />;
};

export default connect(state => state.modalReducer)(ModalRoot);
