import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { configurePersistor, configureStore } from "./redux/store/store";
import { doInit } from "./redux/actions/actions";
import * as serviceWorker from "./serviceWorker";

import App from "./components/App";

/* CSS STYLES*/
import "./index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style/all_icons.min.scss";
import "./style/menu.scss";
import "./style/vendors.scss";
import "./style/style.scss";
import "./style/color-blue.scss";
import "mdbreact/dist/css/mdb.css";
import "../node_modules/bootstrap/scss/bootstrap.scss";
import "react-bootstrap-table/dist/react-bootstrap-table.min.css";

// Redux setup
const store = configureStore();
const persistor = configurePersistor(store);
const wrapper = document.getElementById("root");

// store.dispatch(doInit());

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<div />} persistor={persistor}>
      <Router>
        <App />
      </Router>
    </PersistGate>
  </Provider>,
  wrapper
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
