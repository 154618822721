import React, { Component } from "react";
import { connect } from "react-redux";
import { hideModal, updateReduxPlan } from "../../redux/actions/actions";
import { editUserPlan } from "../../config/config";

import silverPackage from '../../style/img/silver_plan.svg';
import goldPackage from '../../style/img/gold_plan.svg';
import tick from '../../style/img/tick.svg';

class PlanModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPlan: '',
      status: null,
    };
  }

  componentDidMount() {
    this.setState({ selectedPlan: this.props.plan });
  }

  onClose = () => {
    const { dispatch } = this.props;
    dispatch(hideModal());
  };

  onChange = (evt) => {
    this.setState({ selectedPlan: evt });
  };

  onChangePlan = async (e) => {
    e.preventDefault();

    const { selectedPlan } = this.state;
    const { email } = this.props.user
    const { dispatch } = this.props;

    const planData = {
      plan: selectedPlan,
      email: email,
    };

    await editUserPlan({ data: planData })
      .then((response) => {
        if(response.status === 200){
          dispatch( updateReduxPlan(selectedPlan) )
          dispatch(hideModal());
        }
      })
      .catch(() => {
        this.setState({ status: "Something Went Wrong, please try again!" });
      })
  }



  render() {
    const { status } = this.state;

    return (
      <div className="authOverlay">
        <div id="sign-in-dialog" className="zoom-anim-dialog">
          <div className="main_title_2">
            <h3>Subscription Plan</h3>
          </div>
          <div>
            <div className="sign-in-wrapper">
              <form
                onSubmit={(evt) => {
                  evt.preventDefault();
                }}
              >

                <div className="row">
                  <div className="col-lg-6 col-md-6"  onClick={()=> this.onChange('Gold')}>
                    <a className="box_feat" style={this.state.selectedPlan === 'Gold' ? {color: 'green'} : null}>
                      <img src={goldPackage} alt="silver plan Logo" style={{width: 90 , height: 90}} />
                      <h3>GOLD</h3>
                      <ul>
                        <li>description</li>
                      </ul>
                      {this.state.selectedPlan === 'Gold' ? <img src={tick} alt="tick Logo" style={{width: 30 , height: 30}} /> : null}
                    </a>
                  </div>
                  <div className="col-lg-6 col-md-6"  onClick={()=> this.onChange('Silver')}>
                    <a className="box_feat" style={this.state.selectedPlan === 'Silver' ? {color: 'green'} :null}>
                      <img src={silverPackage} alt="silver plan Logo" style={{width: 90 , height: 90}} />
                      <h3>Silver</h3>
                      <ul>
                        <li>description</li>
                      </ul>
                      {this.state.selectedPlan === 'Silver' ? <img src={tick} alt="tick Logo" style={{width: 30 , height: 30}} /> : null}
                    </a>
                  </div>
                </div>

                {status !== null ? (
                  <div className="text-center">
                    <p style={{ color: "red" }}>{this.state.status}</p>
                  </div>
                ) : null}

                <div className="text-center">
                  <input
                    type="submit"
                    value="Change Plan"
                    className="btn_1 full-width"
                    onClick={this.onChangePlan}
                  />
                </div>

                <div className="text-center">
                  <input
                    type="submit"
                    value="Close"
                    className="btn_1 full-width"
                    onClick={this.onClose}
                  />
                </div>


              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.authReducer.user,
  plan: state.authReducer.plan
});

export default connect(mapStateToProps)(PlanModal);
