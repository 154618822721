import React from "react";
//import { Link } from "react-router-dom";

// Components
//import OwlCarousel from "react-owl-carousel2";

const PromotionCarousel = (props) => {
  return (
    <div className="adv_carousel">
      <div
        style={{
          height: 200,
          backgroundColor: "grey",
          opacity: 0.7,
          paddingTop: 10,
        }}>
        <h3>SIGN UP TODAY</h3>
        <div className="row" style={{ fontSize: "18px" }}>
          <div className="col">
            <ul className="text-left ml-4">
              {/* <li>&#8226; 1 WEEK FREE TRIAL</li> */}
              <li>&#8226; 200% - 300% P/A</li>
              <li>&#8226; FULL TRAINING PROVIDED</li>
            </ul>
          </div>
          <div className="col">
            <ul className="text-left">
              <li>&#8226; FREE UPGRADE TO VERSION 2.0 UPON RELEASE</li>
              <li>&#8226; EASY TO USE</li>
              <li>&#8226; FREE AFTER SALES SUPPORT/SERVICE</li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <h3 style={{ color: "black" }}>NEW TO SMART PUNTING ?</h3>
        <p>
          {" "}
          <strong>Contact us</strong> to get started
        </p>
      </div>
    </div>
  );
};

export default PromotionCarousel;
