import React from 'react';
import { connect } from 'react-redux';
import { hideModal } from '../../redux/actions/actions';

class FullscreenModal extends React.Component {
  onClose = () => {
    const { dispatch } = this.props;
    dispatch(hideModal());
  };

  render() {
    const { title, content } = this.props;
    return (
      <div className="authOverlay">
        <div id="sign-in-dialog" className="zoom-anim-dialog">
          <div className="small-dialog-header">
            <h3 style={{ textAlign: "center" }}>{title}</h3>
          </div>
          <div>
            <div className="sign-in-wrapper">
              <p style={{ fontSize: 16, textAlign: "center" }}>{content}</p>
              <button type="button" className="btn_1 full-width" onClick={this.onClose}>
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(FullscreenModal);
