import React, { Component } from "react"
import { connect } from "react-redux"
import moment from 'moment-timezone'

import { hideModal, showModal, updateReduxCoins, updateTransations } from "../../redux/actions/actions"
import { rechargeUserCoins, getUserTransations } from "../../config/config"

import goldCoin from '../../style/img/coin_gold.svg'
import silverCoin from '../../style/img/coin_silver.svg'

class RechargeModal extends Component {
  state = {
    selectedOption: '100',
    cc: null,
    cvc: null,
    exp: null,
    status: null, 
  };

  onClose = () => {
    const { dispatch } = this.props;
    let weekDay = moment().tz('Australia/Sydney').weekday() // SUNDAY IS WEEKDAY NUMBER 0
    if(this.props.races.length === 0 && weekDay !== 6){
      dispatch(showModal('SELECTION_MODAL'))
    }else{
      dispatch(hideModal())
    }
  };

  onChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  onValueChange = (event) => {
    this.setState({
      selectedOption: event.target.value
    });
  }

  showStatus(status) {
    this.setState({ status: status.toUpperCase() });
  }



  onRecharge = async (e) => {
    e.preventDefault();

    const { selectedOption, cc, cvc, exp } = this.state
    const { email, id } = this.props.user
    const { dispatch } = this.props

    const rechargeData = {
      amount: selectedOption,
      cc :cc,
      cvc : cvc,
      exp : exp,
      email: email,
      id: id,
    };

    await rechargeUserCoins({ data: rechargeData })
      .then(async (response) => {
        if (response.message) {
          this.showStatus(response.message);
          if(response.status === 200){
            dispatch( updateReduxCoins(parseInt(selectedOption,10)) )
            await getUserTransations({clientID: id }).then((response) => {
              if (response.status === 200) {
                dispatch(updateTransations(response.data))
              }else{
                this.setState({message: 'No records Found'})
              }
            })
          }
          return;
        }
      })
      .catch(() => {
        this.setState({ status: "Something Went Wrong, please try again!" });
      })
  }


  render() {
    const { status } = this.state
    const { plan } = this.props
    return (
      <div className="authOverlay">
        <div id="sign-in-dialog" className="zoom-anim-dialog">
          <div className="small-dialog-header">
            <h3>Recharge Coins</h3>
          </div>
          <div>
            <div className="sign-in-wrapper">
              <form
                onSubmit={(evt) => {
                  evt.preventDefault();
                }}
              >
                <div className="rechargeRadio">
                  <input
                    name="debt-amount" id="1"
                    type="radio"
                    value='5'
                    checked={this.state.selectedOption === '5'}
                    onChange={this.onValueChange}
                  />
                  <label htmlFor="1">
                    {plan === 'Silver' ?
                      <><div style={{marginTop:50, marginLeft: 20}}> 5 <img src={silverCoin} alt="silver coins" style={{width: 20 ,height: 20}} /> </div>
                        <div style={{fontSize: 14, textAlign: 'center'}}> $ 13.75 </div> </>
                      :
                      <><div style={{marginTop:50, marginLeft: 20}}> 5 <img src={goldCoin} alt="gold coins" style={{width: 20 ,height: 20}} /> </div>
                        <div style={{marginLeft: 6}}> $ 13.75 </div> </>}
                  </label>

                  <input
                    name="debt-amount" id="2"
                    type="radio"
                    value='25'
                    checked={this.state.selectedOption === '25'}
                    onChange={this.onValueChange}
                  />
                  <label htmlFor="2">
                    {plan === 'Silver' ?
                      <><div style={{marginTop:50, marginLeft: 20}}> 25 <img src={silverCoin} alt="silver coins" style={{width: 20 ,height: 20}} /> </div>
                        <div style={{fontSize: 14, textAlign: 'center'}}> $ 55.00 </div>
                        <div style={{fontSize: 14, textAlign: 'center'}}> 5 Free </div>
                        <div style={{fontSize: 14, width: 60, marginLeft: 10, backgroundColor: '#d69c1f', color: 'white', borderRadius: 10, textAlign: 'center'}}> - {(25*2.75-55)*100/55}%</div></>
                      :
                      <><div style={{marginTop:50, marginLeft: 20}}> 25 <img src={goldCoin} alt="gold coins" style={{width: 20 ,height: 20}} /> </div>
                        <div style={{marginLeft: 6}}> $ 55.00 </div> </>}
                  </label>

                  <input
                    name="debt-amount" id="3"
                    type="radio"
                    value='50'
                    checked={this.state.selectedOption === '50'}
                    onChange={this.onValueChange}
                  />
                  <label htmlFor="3">
                    {plan === 'Silver' ?
                      <><div style={{marginTop:50, marginLeft: 20}}> 50 <img src={silverCoin} alt="silver coins" style={{width: 20 ,height: 20}} /> </div>
                        <div style={{fontSize: 14, textAlign: 'center'}}> $ 96.25 </div>
                        <div style={{fontSize: 14, textAlign: 'center'}}> 15 Free </div>
                        <div style={{fontSize: 14, width: 60, marginLeft: 10, backgroundColor: '#db5a1a', color: 'white', borderRadius: 10, textAlign: 'center'}}> - {((50*2.75- 96.25)*100/96.25).toFixed(1)}%</div></>
                      :
                      <><div style={{marginTop:50, marginLeft: 20}}> 25 <img src={goldCoin} alt="gold coins" style={{width: 20 ,height: 20}} /> </div>
                        <div style={{marginLeft: 6}}> $ 55.00 </div> </>}
                  </label>

                  <input
                    name="debt-amount" id="4"
                    type="radio"
                    value='100'
                    checked={this.state.selectedOption === '100'}
                    onChange={this.onValueChange}
                  />
                  <label htmlFor="4">
                    {plan === 'Silver' ?
                      <><div style={{marginTop:50, marginLeft: 20}}> 100 <img src={silverCoin} alt="silver coins" style={{width: 20 ,height: 20}} /> </div>
                        <div style={{fontSize: 14, textAlign: 'center'}}> $ 165 </div>
                        <div style={{fontSize: 14, textAlign: 'center'}}> 40 Free </div>
                        <div style={{fontSize: 14, width: 60, marginLeft: 10, backgroundColor: '#db2d1a', color: 'white', borderRadius: 10, textAlign: 'center'}}> - {((100*2.75- 165)*100/165).toFixed(1)}%</div></>
                      :
                      <><div style={{marginTop:50, marginLeft: 20}}> 25 <img src={goldCoin} alt="gold coins" style={{width: 20 ,height: 20}} /> </div>
                        <div style={{marginLeft: 6}}> $ 55.00 </div> </>}
                  </label>

                  {/*<input
                    name="debt-amount" id="4"
                    type="radio"
                    value='100'
                    checked={this.state.selectedOption === '100'}
                    onChange={this.onValueChange}
                  />
                  <label htmlFor="4" data-debt-amount="50$"></label>*/}

                  <div id="debt-amount-pos"></div>

                </div>

                <div className="rechargeInputs">
                  <label>Card Number</label>
                  <input
                    type="text"
                    className="form-control"
                    name="cc"
                    id="cc"
                    onChange={this.onChange}
                  />
                </div>
                <div className="rechargeInputs">
                  <label>Expiry Date</label>
                  <input
                    type="text"
                    className="form-control"
                    name="exp"
                    id="exp"
                    onChange={this.onChange}
                  />
                </div>
                <div className="rechargeInputs">
                  <label>cvc</label>
                  <input
                    type="text"
                    className="form-control"
                    name="cvc"
                    id="cvc"
                    onChange={this.onChange}
                  />
                </div>

                {status !== null ? (
                  <div className="text-center">
                    <p style={{ color: "red" }}>{this.state.status}</p>
                  </div>
                ) : null}

                <div className="text-center">
                  <input
                    type="submit"
                    value="Recharge"
                    className="btn_1 full-width"
                    onClick={this.onRecharge}
                  />
                </div>

                <div className="text-center">
                  <input
                    type="submit"
                    value="Close"
                    className="btn_1 full-width"
                    onClick={this.onClose}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.authReducer.user,
  plan: state.authReducer.plan,
  races: state.raceReducer.races,
});

export default connect(mapStateToProps)(RechargeModal);
