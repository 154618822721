import {
  loginUserAuth,
  deleteHistory,
  msgReadByClient,
  getMessages,
  delMessages,
  appoinTmentID,
  ifloggedIn,
} from "../../config/config";
import { generateUserSession } from "../../utils/utils";

// //////////////////////////////////
// Action types
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const ALREADY_LOGIN = "ALREADY_LOGIN";
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const TABLE_DATA = "TABLE_DATA";
export const UPDATE_AMOUNT = "UPDATE_AMOUNT";
export const UPDATE_ALLAMOUNT = "UPDATE_ALLAMOUNT";
export const UPDATE_PLAN = "UPDATE_PLAN";
export const UPDATE_PAUSED = "UPDATE_PAUSED";
export const UPDATE_RACES = "UPDATE_RACES";
export const UPDATE_SATPAUSED = "UPDATE_SATPAUSED";
export const UPDATE_POPPAUSED = "UPDATE_POPPAUSED";
export const UPDATE_TRANSACTIONS = "UPDATE_TRANSACTIONS";
export const UPDATE_COINSSUMMARY = "UPDATE_COINSSUMMARY";
export const PATH_NAME = "PATH_NAME";
export const DEL_HISTORY = "DEL_HISTORY";
export const MSG_CLIENT = "MSG_CLIENT";
export const GET_MESSAGES = "GET_MESSAGES";
export const DELETE_MESSAGES = "DELETE_MESSAGES";
export const GET_LEADBYID_STARTED = "GET_LEADBYID_STARTED";
export const GET_LEADBYID_SUCCESS = "GET_LEADBYID_SUCCESS";
export const GET_LEADBYID_ERROR = "GET_LEADBYID_ERROR";

// //////////////////////////////////
// Action creators

// export const getLeadById = (data) => {
//   console.log(data)
//   return async (dispatch) => {
//     let response = await appoinTmentID(data);
//     dispatch({
//       type: 'GET_LEADBYID',
//       response
//     })
//   }
// }

// export const LeadActions = {
//   fetchInfoId: (filter, keepPagination = false) => async (dispatch) => {
//     try {
//       dispatch({
//         type: GET_LEADBYID_STARTED,
//         payload: { filter, keepPagination }
//       })
//       let response = await appoinTmentID();
//       dispatch({
//         type: GET_LEADBYID_SUCCESS,
//         payload: {
//           data: response,
//         }
//       })
//     } catch (error) {
//       console.log("ERROR getting data lead by ID: " + error)
//       dispatch({
//         type: GET_LEADBYID_ERROR
//       })
//     }
//   }
// }

export const loginUser = (user) => ({
  type: LOGIN_USER,
  user,
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
});

export const showModal = (modalType, modalProps) => ({
  type: SHOW_MODAL,
  modalType,
  modalProps,
});

export const hideModal = () => ({
  type: HIDE_MODAL,
});

export const storeHomeTable = (data) => ({
  type: TABLE_DATA,
  data,
});

export const updateReduxCoins = (amount) => ({
  type: UPDATE_AMOUNT,
  amount,
});

export const updateReduxAllCoins = (amount) => ({
  type: UPDATE_ALLAMOUNT,
  amount,
});

export const updateReduxPlan = (plan) => ({
  type: UPDATE_PLAN,
  plan,
});

export const updateRedPaused = (paused) => ({
  type: UPDATE_PAUSED,
  paused,
});

export const updateRedSatPaused = (satPaused) => ({
  type: UPDATE_SATPAUSED,
  satPaused,
});

export const updateRechargePopup = (rPopup) => ({
  type: UPDATE_POPPAUSED,
  rPopup,
});

export const updateTransations = (transactions) => ({
  type: UPDATE_TRANSACTIONS,
  transactions,
});

export const updateCoinsSummary = (coinsSummary) => ({
  type: UPDATE_COINSSUMMARY,
  coinsSummary,
});

export const updateRaces = (races, date, totLeft) => ({
  type: UPDATE_RACES,
  races,
  date,
  totLeft,
});

export const pathName = (url) => ({
  type: PATH_NAME,
  url,
});

export const delHistory = (item, clientID) => async (dispatch) => {
  await deleteHistory(item, clientID);
};
export const msgClient = (msg, clientID) => async (dispatch) => {
  await msgReadByClient(msg, clientID);
};
export const historyMessages = () => {
  return async (dispatch) => {
    let response = await getMessages();
    dispatch({
      type: "GET_MESSAGES",
      response,
    });
  };
};

export const deleteMessages = (data) => {
  return async (dispatch) => {
    console.log(data);
    let response = await delMessages(data);
    dispatch({
      type: "DELETE_MESSAGES",
      response,
    });
  };
};

export function doinitAlready(data) {
  return async (dispatch) => {
    try {
      console.log(data);
    } catch (err) {
      console.log("err already ", err);
    }
  };
}

export function doInit() {
  return async (dispatch) => {
    try {
      let token = localStorage.getItem("SPToken");
      if (token) {
        let tok = JSON.parse(token);
        console.log(tok);
        await loginUserAuth({ email: tok.email, password: tok.password }).then(
          (response) => {
            if (response.message) {
              return;
            } else {
              //localStorage.removeItem('SPToken')
              //localStorage.setItem('SPToken', JSON.stringify(response))
              generateUserSession(response);
              dispatch(
                loginUser({
                  id: response.id,
                  name: response.name,
                  email: response.email,
                  password: response.password,
                  plan: response.plan,
                  coins: response.coins,
                  phone: response.phone,
                  country: response.country,
                  region: response.region,
                  dob: response.dob,
                  paused: response.paused,
                  satPaused: response.satPaused,
                  rPopup: response.rPopup,
                  bankBalance: response.bankBalance,
                  balance: response.balance,
                  cycle: response.cycle,
                  target: response.target,
                  clientCreationDate: response.clientCreationDate,
                  isPlat: true,
                  isBronze: true,
                })
              );
              if (response.coins <= 10 && response.rPopup === 0) {
                //dispatch(showModal('RECHARGE_MODAL'));
              }
            }
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
}
