import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import { hideModal, loginUser, showModal } from "../../redux/actions/actions";
import { loginUserAuth, saveSessionID } from "../../config/config";
import { generateUserSession } from "../../utils/utils";

class AuthenticationModal extends Component {
  state = {
    email: "",
    password: "",
    status: null,
  };

  onClose = () => {
    const { dispatch } = this.props;
    dispatch(hideModal());
  };

  onChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  onLogin = () => {
    const { email, password } = this.state;
    const { dispatch } = this.props;
    trackPromise(
      loginUserAuth({ email, password })
        .then((response) => {
          generateUserSession(response);
          if (response.message) {
            this.showStatus(response.message);
            return;
          } else {
            localStorage.setItem("SPToken", JSON.stringify(response));
            dispatch(
              loginUser({
                id: response.id,
                name: response.name,
                email: response.email,
                password: response.password,
                plan: response.plan,
                coins: response.coins,
                phone: response.phone,
                country: response.country,
                region: response.region,
                dob: response.dob,
                paused: response.paused,
                satPaused: response.satPaused,
                rPopup: response.rPopup,

                bankBalance: response.bankBalance,
                balance: response.balance,
                cycle: response.cycle,
                target: response.target,
                clientCreationDate: response.clientCreationDate,
                clientExpiryDate: response.clientExpiryDate,
                messageID: response.messageID,
                isPlat: response.isPlat,
                isBronze: response.isBronze,
              })
            );
            //window.location.reload();
            if (response.coins <= 10 && response.rPopup === 0) {
              dispatch(showModal("RECHARGE_MODAL"));
            } else {
              dispatch(hideModal());
            }
            this.props.history.push("/stats");
          }
        })
        .catch(() => {
          this.setState({ status: "Please input proper data !" });
        })
    );
  };

  showStatus(status) {
    this.setState({ status: status.toUpperCase() });
  }

  onForgot = (evt) => {
    evt.preventDefault();
    const { dispatch } = this.props;
    dispatch(showModal("FORGOTPASS_MODAL", null));
  };

  render() {
    const { status } = this.state;
    return (
      <div className="authOverlay">
        <div id="sign-in-dialog" className="zoom-anim-dialog">
          <div className="small-dialog-header">
            <h3>Sign In</h3>
          </div>
          <div>
            <div className="sign-in-wrapper">
              <form
                onSubmit={(evt) => {
                  evt.preventDefault();
                }}>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    autoComplete="username"
                    type="text"
                    className="form-control"
                    name="email"
                    id="email"
                    onChange={this.onChange}
                  />
                  <i className="icon_mail_alt" />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input
                    autoComplete="current-password"
                    type="password"
                    className="form-control"
                    name="password"
                    id="password"
                    onChange={this.onChange}
                  />
                  <i className="icon_lock_alt" />
                </div>
                <div className="clearfix add_bottom_15">
                  <div className="float-right mt-1" onClick={this.onForgot}>
                    <a className="forgot" href="#">
                      Forgot Password?
                    </a>
                  </div>
                </div>
                {status !== null ? (
                  <div className="text-center">
                    <p style={{ color: "red" }}>{status}</p>
                  </div>
                ) : (
                  <div />
                )}
                <div className="text-center">
                  <input
                    type="submit"
                    value="Log In"
                    className="btn_1 full-width"
                    onClick={this.onLogin}
                  />
                </div>
                <div className="text-center">
                  <input
                    type="submit"
                    value="Close"
                    className="btn_1 full-width"
                    onClick={this.onClose}
                  />
                </div>
                <div className="text-center">
                  Don’t have an account? <a href="/signUp">Sign up</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(withRouter(AuthenticationModal));
