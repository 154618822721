import React from 'react';
import { Link } from 'react-router-dom';

// Images
import SpsLogo from '../../style/img/SpsLogo.png';
//import PaymentCards from '../../style/img/cards_all.svg';

const Footer = () => (
  
    <footer>
      <div className="container margin_60_35">
        <div className="row">
          <div className="col-lg-5 col-md-12 p-r-5">
            <p>
              <img src={SpsLogo} width={350} height={45} data-retina="true" alt="logo" />
            </p>
            {/* <p>
              The philosophy behind the development of Smart Punting System is Ease of Use, Consistency, and
              Accessibility for a massive betting profit in horse racing industry.
            </p> */}
          </div>
          <div className="col-lg-3 col-md-6 ml-lg-auto">
            {/* <h5>Useful links</h5>
            <ul className="links">
              <li>
                <Link to="/aboutUs">About Us</Link>
              </li>
              <li>
                <Link to="/contactUs">Contact Us</Link>
              </li>
              <li>
                <Link to="/disclaimer">Disclaimer</Link>
              </li>
              <li>
                <Link to="/faq">FAQ</Link>
              </li>
              <li>
                <Link to="/terms">Terms and conditions</Link>
              </li>
              <li>
                <Link to="/privacy">Privacy</Link>
              </li>
            </ul> */}
          </div>
          <div className="col-lg-3 col-md-6">
            <h5>Contact Us</h5>
            <ul className="contacts">
              <li>
                <a href="mailto:support@spstips.com.au">
                  <i className="ti-email" />
                  support@spstips.com.au
                </a>
              </li>
            </ul>
            <div className="follow_us">
              <ul>
                <li>Follow us on Social Media</li>
              </ul>
              <ul>
                <li>
                  <a href="https://www.facebook.com/SPSTIPSAU" target="blank">
                    <i className="ti-facebook" />
                  </a>
                </li>
                {/* <li>
                  <a href="#0" >
                    <i className="ti-twitter-alt" />
                  </a>
                </li>
                <li>
                  <a href="#0">
                    <i className="ti-linkedin" />
                  </a>
                </li>
                <li>
                  <a href="#0">
                    <i className="ti-skype" />
                  </a>
                </li> */}
              </ul>
            </div>
            {/*<div id="newsletter">
              <h6>Newsletter</h6>
              <div id="message-newsletter" />
              <form
                method="post"
                action="assets/newsletter.php"
                name="newsletter_form"
                id="newsletter_form"
              >
                <div className="form-group">
                  <input
                    type="email"
                    name="email_newsletter"
                    id="email_newsletter"
                    className="form-control"
                    placeholder="Your email"
                  />
                  <input type="submit" defaultValue="Submit" id="submit-newsletter" />
                </div>
              </form>
            </div>*/}
          </div>
        </div>
        <hr />
          <div>
            <ul id="additional_links">
              <li>
                <span>{new Date().getFullYear()} &copy; Smart Punting System</span>
              </li>
            </ul>
          </div>
        </div>

    </footer>
);

export default Footer;
