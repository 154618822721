import React, { Component } from "react";
import PromotionCarousel from "../../components/advertisementCarousel/promotionCarousel";

class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = "Abbreviations";
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  render() {
    let ls = window.innerWidth > 1224 ? "3em" : "";
    return (
      <div className="hero_single version_2">
        <div
          style={{
            backgroundColor: "black",
            opacity: 0.8,
            width: "90%",
            height: '60%',
            margin: "auto",
            padding: 20,
            marginTop: 80,
            overflow: "auto",
            lineHeight: ls,
          }}
        >
          <p style={{ fontSize: 36}}>PROGRAM ABBREVIATIONS</p>
          <p style={{ textAlign: "justify", fontSize: 16 }}>
            First fixed line starting from the left. (Top Left) <br />
            &#10148; Clients name or I.D <br />
            &#10148; R.CYC (Restart cycle). <br />
            &#10148; Drop down bar. (DesiredTarget). <br />
            &#10148; Amount (In trading account). <br />
            &#10148; Notices.
          </p>
          <p style={{ textAlign: "justify", fontSize: 16 }}>
            Second fixed line starting from the left. <br />
            &#10148; R. INFO (Date, Race time, Venue & Race number). <br />
            &#10148; GD (Selection on a GOOD track condition). <br />
            &#10148; MX (Wagers on the GD selection, should not exceed this figure). <br />
            &#10148; SFT (Selection on a SOFT track condition).  <br />
            &#10148; MX (Wagers on a SFT selection, should not exceed this figure). <br />
            &#10148; SYN (Selection on a SYNTHETIC track condition). <br />
            &#10148; MX (Wagers on a SYN selection, should not exceed this figuer). <br />
            &#10148; W.($) (Enter the WIN price, in this column). <br />
            &#10148; PLC.($) (Enter the PLACE price, in this column). <br />
            &#10148; CLC (Calculates the amount to wager, for the WIN & PLACE). <br />
            &#10148; UNDO (Click on UNDO, to rectify any mistakes). <br />
            &#10148; W. (Amount to wager for the WIN). <br />
            &#10148; PL. (Amount to wager for the PLACE). <br />
            &#10148; RES. (Click on the relevant RESULT). (Win, Place, Lost or Cancel). <br />
            &#10148; $AMT (Dollar value of race result, WON, LOST or N/A). <br />
            &#10148; BAL (Account BALANCE). <br />
          </p>
        </div>
        {window.innerWidth > 1224 ? <PromotionCarousel /> : null}
      </div>
    );
  }
}

export default Summary;
