import axios from "axios";
import socketIOClient from "socket.io-client";

const hostApi =
  process.env.NODE_ENV === "development" ? "http://localhost" : ``;
const portApi = process.env.NODE_ENV === "development" ? 3060 : null;

const SERVER_URL =
  process.env.NODE_ENV === "development" ? `http://localhost:3060` : null;
const BASE_URL = `${hostApi}${portApi ? `:${portApi}` : ``}/api`;

export const socket = socketIOClient(SERVER_URL, {
  transports: ["websocket"],
  upgrade: false,
});

const config = {
  headers: {
    Authorization: `SMARTPUNTING c91fe46c-7033-4525-87cc-08f81e3f9439`,
  },
};

function loginUserAuth(credentials) {
  const url = `${BASE_URL}/clients/login`;
  return axios.post(url, credentials, config).then((response) => response.data);
}

function ifloggedIn(data) {
  const url = `${BASE_URL}/clients/islogin`;
  return axios.post(url, data, config).then((response) => response.data.data);
}

async function saveSessionID(data) {
  const url = `${BASE_URL}/clients/savesessionid`;
  return await axios.post(url, data, config).then((response) => response.data);
}

function logoutUserAuth(email) {
  const url = `${BASE_URL}/clients/logout`;
  return axios.post(url, email, config).then((response) => response.data);
}

function signUpUser(data) {
  const url = `${BASE_URL}/clients/signUp`;
  return axios.post(url, data, config).then((response) => response.data);
}

function forgotUserAuth(data) {
  const url = `${BASE_URL}/clients/forgotPassword`;
  return axios.post(url, data, config).then((response) => response.data);
}

function contactUs(data) {
  const url = `${BASE_URL}/clients/contactUs`;
  return axios.post(url, data, config).then((response) => response.data);
}

function appoinTment(data) {
  const url = `${BASE_URL}/clients/appointment`;
  return axios.post(url, data, config).then((response) => response.data);
}

function appoinTmentID(data) {
  const url = `${BASE_URL}/clients/appointments/${data.data}`;
  return axios.post(url, data, config).then((response) => response.data);
}

// function saveLeadsAppointmentId(data) {
//   console.log(data)
//   const url = `${BASE_URL}/clients/appointments/:id`;
//   return axios.post(url, data, config).then((response) => response.data);
// }

function rechargeUserCoins(data) {
  const url = `${BASE_URL}/clients/rechargeUserCoins`;
  return axios.post(url, data, config).then((response) => response.data);
}

function editProfile(data) {
  const url = `${BASE_URL}/clients/editProfile`;
  return axios.post(url, data, config).then((response) => response.data);
}

function updatePauseMessages(data) {
  const url = `${BASE_URL}/clients/updatePauseMessages`;
  return axios.post(url, data, config).then((response) => response.data);
}

function updateSatPauseMessages(data) {
  const url = `${BASE_URL}/clients/updateSatPauseMessages`;
  return axios.post(url, data, config).then((response) => response.data);
}

function updateAutoRecharge(data) {
  const url = `${BASE_URL}/clients/updateAutoRecharge`;
  return axios.post(url, data, config).then((response) => response.data);
}

function editUserPlan(data) {
  const url = `${BASE_URL}/clients/editUserPlan`;
  return axios.post(url, data, config).then((response) => response.data);
}

function verifyAccount(id) {
  const url = `${BASE_URL}/clients/verify/${id}`;
  return axios.get(url, config).then((response) => response.data);
}

function homeTableInfo() {
  const url = `${BASE_URL}/clients/homeTableInfo`;
  return axios.get(url, config).then((response) => response.data);
}

function sendRestSelection(data) {
  const url = `${BASE_URL}/clients/sendDailySelection`;
  return axios.post(url, data, config).then((response) => response.data);
}

function getSilverRaces(clientID) {
  const url = `${BASE_URL}/clients/getSilverRaces`;
  return axios.post(url, clientID, config).then((response) => response.data);
}

function userBuyTips(data) {
  const url = `${BASE_URL}/clients/userBuyTips`;
  return axios.post(url, data, config).then((response) => response.data);
}

function getUserTransations(data) {
  const url = `${BASE_URL}/clients/getUserTransations`;
  return axios.post(url, data, config).then((response) => response.data);
}

function getUserCoinsHistory(data) {
  const url = `${BASE_URL}/clients/getUserCoinsHistory`;
  return axios.post(url, data, config).then((response) => response.data);
}

/*RACES*/
function fetchRaces(data) {
  const url = `${BASE_URL}/calculator/listRaces`;
  return axios.post(url, data, config).then((response) => response);
}

function saveClient(data) {
  const url = `${BASE_URL}/calculator/saveClient`;
  return axios.post(url, data, config).then((response) => response.data);
}

function deleteClientRace(data) {
  const url = `${BASE_URL}/calculator/deleteClientRace`;
  return axios.post(url, data, config).then((response) => response.data);
}

function getClients() {
  const url = `${BASE_URL}/calculator/retrieveClients`;
  return axios.get(url, config).then((response) => response.data);
}

function getClient(clientID) {
  const url = `${BASE_URL}/clients/retrieveClient`;
  return axios
    .post(url, { clientID: clientID }, config)
    .then((response) => response.data);
}

const getUnCalRaces = async (RACEIDS) => {
  const url = `${BASE_URL}/calculator/uncalcraces`;
  return axios
    .post(url, { RACEIDS: RACEIDS }, config)
    .then((response) => response.data);
};

function saveCalcResults(data, clientID) {
  const url = `${BASE_URL}/calculator/saveClientRaces`;
  return axios.post(url, data, clientID, config).then((response) => response);
}

function checkAvailability(clientID) {
  let data = {
    clientID: clientID,
  };
  const url = `${BASE_URL}/calculator/checkavailability`;
  return axios.post(url, data, config).then((response) => response.data);
}

const deleteHistory = async (item, clientID) => {
  const url = `${BASE_URL}/calculator/deleteHistory`;
  return axios
    .post(url, { item: item, clientID: clientID }, config)
    .then((response) => response.data);
};

const msgReadByClient = async (msg, clientID) => {
  const url = `${BASE_URL}/calculator/msgReadByClient`;
  return axios
    .post(url, { msg: msg, clientID: clientID }, config)
    .then((response) => response.data);
};

const getMessages = async () => {
  const url = `${BASE_URL}/calculator/messages`;
  return axios.get(url, config).then((response) => response.data);
};

const delMessages = async (data) => {
  const url = `${BASE_URL}/calculator/deleteMessages`;
  return axios.post(url, data, config).then((response) => response.data);
};

const checkLiveMsg = async (data) => {
  const url = `${BASE_URL}/clients/checkLiveMsg`;
  return axios.post(url, data, config).then((response) => response.data);
};

export {
  // saveLeadsAppointmentId,
  ifloggedIn,
  appoinTmentID,
  loginUserAuth,
  logoutUserAuth,
  signUpUser,
  forgotUserAuth,
  verifyAccount,
  homeTableInfo,
  fetchRaces,
  saveCalcResults,
  checkAvailability,
  contactUs,
  editProfile,
  rechargeUserCoins,
  editUserPlan,
  updatePauseMessages,
  updateSatPauseMessages,
  updateAutoRecharge,
  sendRestSelection,
  getSilverRaces,
  userBuyTips,
  getUserTransations,
  getUserCoinsHistory,
  saveClient,
  getClients,
  getClient,
  deleteClientRace,
  getUnCalRaces,
  deleteHistory,
  msgReadByClient,
  saveSessionID,
  getMessages,
  delMessages,
  appoinTment,
  checkLiveMsg,
};
