import { saveSessionID } from "../config/config"
import { v4 as uuidv4 } from "uuid";
import { socket } from "../config/config";
import { logoutUser } from "../redux/actions/actions";


const generateUserSession = async (response) => {
    localStorage.setItem("SPSsession", null);
    let sessionID = uuidv4();
    if(response.id){
      let data = {
        sessionID: sessionID,
        clientID: response.id,
      };
  
      // SAVE A SESSION ID INTO THE DB.
      saveSessionID(data).then(res=>{
        localStorage.setItem("SPSsession", JSON.stringify(res));
      });
    }
}

const loadSession = async(client, SPSsession, props) => {
  try {
    socket.on("loadSession", (data) => handleSession(data, client, SPSsession, props));
    socket.emit("loadSession", { client: client });
  } catch (error) {
    console.log("error loading session");
  }
}

const handleSession = (data, client, SPSsession, props) => {
  try {
    const { session } = data;
    if (SPSsession) {
      if (SPSsession.clientID === client.id) {
        if (session.session_id !== SPSsession.sessionID && session.client_id === client.id) {
          console.log(session.session_id);
          localStorage.setItem("SPSsession", null);
          console.log("logout the user");
          socket.off("loadSession");
          logout(props);
        }
      }
    }else{
      logout(props);
    }
  } catch (error) {
    console.log("error handling the session");
  }
}

const logout = (props) => {
  if (props) {
    const { dispatch } = props;
    dispatch(logoutUser());
    props.history.push("/");
  }
}

const shutdownSocket = () => {
  socket.off("loadSession");
}

const emailValidator = (email) => {
  let response = false;
  try {
    let filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!filter.test(email)) {
      response = false;
    } else {
      response = true;
    }
  } catch (error) {
    console.log(error);
    response = false;
  } finally {
    return response;
  }
};
export{
    generateUserSession,
    loadSession,
    shutdownSocket,
    emailValidator,
}