import React, { Component } from "react";
import { connect } from "react-redux";
import { hideModal, showModal, updateRaces, updateReduxAllCoins } from "../../redux/actions/actions";
import { userBuyTips } from "../../config/config";

class SelectionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tips: 0,
      status: null,
    };
  }

  onClose = () => {
    const { dispatch } = this.props;
    dispatch(hideModal());
  };

  onConfirm = async (e) => {
    e.preventDefault();
    const { dispatch, totLeft, user, coins } = this.props;
    if(this.state.tips === 0){
      this.setState({ status: '0 cannot be selected'})
    }else{
      if(this.state.tips > totLeft){
        this.setState({ status: 'Number of tips selected should not exceed '+ totLeft })
      }else{
        if(this.state.tips > coins ){
          this.setState({ status: 'Insufficient coins in your balance, Please recharge and try again' })
        }else{
          this.setState({ status: null })
          await userBuyTips({email: user.email, name: user.name, clientID: user.id, selectedTips: this.state.tips }).then((response) => {
            if (response.status === 200) {
              // here we also have to update the coins left in client account
              // and reload the window
              dispatch(updateRaces(response.data, response.date, response.totLeft))
              dispatch(updateReduxAllCoins(response.coins))
              dispatch(hideModal())
            }else{
              this.setState({ status: response.message })
            }
          }).catch(err => {
                    this.setState({ status: 'Server error please try again, or contact customer support' })
                    console.log(err)
          } )
        }
      }
    }
  }

  onChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  onRecharge = async (e) => {
    e.preventDefault();
    const { dispatch } = this.props;

    dispatch(
      showModal('RECHARGE_MODAL')
    );
  }



  render() {
    const { totLeft, coins } = this.props
    const { status } = this.state;

    return (
      <div className="authOverlay">
        <div id="sign-in-dialog" className="zoom-anim-dialog">
          <div className="main_title_2">
            <h3>Selections</h3>
          </div>
          <div>
            <div className="sign-in-wrapper">
              <form
                onSubmit={(evt) => {
                  evt.preventDefault();
                }}
              >
                {totLeft ===0 ?
                  <div className="row" style={{marginBottom: 20}}>
                    <div className="col-lg-12">
                      No selections left for today. Come back Tomorrow for more selections.
                    </div>
                  </div> : 
                  <div className="row">
                    <div className="col-lg-12">
                      <span style={{fontSize: 20, fontWeight:'bold'}}>{totLeft}</span> selections left for today. <br/>
                      <div className="rechargeInputs">
                        <label>Buy the next:</label>
                        <input
                          type="number" inputmode="numeric"
                          min="0" 
                          max={totLeft}
                          className="form-control"
                          name="tips"
                          id="tips"
                          placeholder="Number of tips"
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                  </div>}

                {status !== null ? (
                  <div className="text-center">
                    <p style={{ color: "red" }}>{this.state.status}</p>
                  </div>
                ) : null}

                {coins < totLeft ?
                  <>
                  <div style={{color: 'darkgreen', textAlign: 'justify', marginBottom: 10}}>
                    Your balance is less the the left selections for today, click recharge for more tips and coins.
                  </div>
                  <div className="text-center">
                    <input
                      type="submit"
                      value="Recharge"
                      className="btn_1 full-width"
                      style={{ backgroundColor: '#343a40' }}
                      onClick={this.onRecharge}
                    />
                  </div>
                  </>
                :
                  null
                }

                {totLeft !== 0 ?
                  <div className="text-center">
                    <input
                      type="submit"
                      value="Confirm"
                      className="btn_1 full-width"
                      onClick={this.onConfirm}
                    />
                  </div> : null }

                <div className="text-center">
                  <input
                    type="submit"
                    value="Close"
                    className="btn_1 full-width"
                    onClick={this.onClose}
                  />
                </div>


              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.authReducer.user,
  plan: state.authReducer.plan,
  coins: state.authReducer.coins,
  totLeft: state.raceReducer.totLeft,
});

export default connect(mapStateToProps)(SelectionModal);
